import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import { Table as ResponsiveTable, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import { Spinner } from 'react-bootstrap'
import { DateTime } from 'luxon'

import './Table.scss'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'

function Table({
  align,
  bodyContent,
  border,
  className,
  cellSpacing,
  cellPadding,
  error,
  headers,
  loading,
  onClickCell,
  style,
}) {
  if (loading) {
    return (
      <div className="d-flex justify-content-center my-3">
        <Spinner animation="border" />
      </div>
    )
  }

  return (
    <ResponsiveTable
      align={align}
      border={border}
      className={ClassNames('custom-table', { 'custom-table--initial': cellSpacing }, className)}
      cellPadding={cellPadding}
      cellSpacing={cellSpacing}
      style={style}
    >
      <Thead>
        <Tr className="bg-primary">
          {headers?.map((header) => (
            <Th
              className={ClassNames('text-white text-small custom-table__header', { 'p-md-3': !cellPadding })}
              key={header}
              {...header}
            >
              {header.content || header}
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {Array.isArray(bodyContent) &&
          bodyContent.map((content, index) => (
            <Tr
              data-index={index}
              className={ClassNames('custom-table__row', index % 2 === 0 ? 'bg-gray-300' : 'bg-gray-400', {
                clickable: onClickCell,
              })}
              onClick={onClickCell}
              key={`custom-table-body-${index}`}
            >
              {Object.keys(content).map((fieldName, index) => (
                <Td
                  key={content[fieldName].content || content[fieldName] + index}
                  className={ClassNames('text-small text-dark-contrast-color custom-table__cell', {
                    'py-2 py-md-2 px-md-3': !cellPadding,
                  })}
                  style={content[fieldName].style}
                  {...content[fieldName]}
                >
                  <span>
                    {content[fieldName].toString().match(/[0-9]+-[0-9]+-[0-9]+/)
                      ? DateTime.fromJSDate(new Date(content[fieldName])).toLocaleString()
                      : content[fieldName].content || content[fieldName]}
                  </span>
                </Td>
              ))}
            </Tr>
          ))}
      </Tbody>
      {error && <p className="text-center">{error}</p>}
    </ResponsiveTable>
  )
}

Table.defaultProps = {
  border: 1,
}

Table.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right']),
  bodyContent: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.node])),
  border: PropTypes.number,
  cellSpacing: PropTypes.number,
  cellPadding: PropTypes.number,
  className: PropTypes.string,
  error: PropTypes.string,
  headers: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.node, PropTypes.object])).isRequired,
  loading: PropTypes.bool,
  onClickCell: PropTypes.func,
}

export default Table
