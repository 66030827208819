import React, { useState } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import PropTypes from 'prop-types'
import './ComboBox.scss'

function ComboBox({ label, options, selected, type, onChange }) {
  const [optionSelected, setOptionSelected] = useState(selected)

  const handleChange = (option) => {
    setOptionSelected(option)
    if (onChange) onChange(option)
  }

  return (
    <>
      {label && <p className={`ComboBox__combo-box${type === 'link' ? '-link' : ''}`}>{label}</p>}
      <Dropdown>
        <Dropdown.Toggle variant={type === 'link' ? 'white' : 'dark'}>{optionSelected?.name}</Dropdown.Toggle>
        <Dropdown.Menu popperConfig={{ strategy: 'fixed' }}>
          {Array.isArray(options) &&
            options.map((option, index) => (
              <Dropdown.Item key={index} onClick={() => handleChange(option)}>
                {option.name}
              </Dropdown.Item>
            ))}
        </Dropdown.Menu>
      </Dropdown>
    </>
  )
}

ComboBox.propTypes = {
  label: PropTypes.string,
  selected: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
      sortOrder: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
  type: PropTypes.string,
}

export default ComboBox
