import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'

import ContentBlock from 'components/atoms/ContentBlock'
import Typography from 'components/atoms/Typography'
import SectionTitle from 'components/molecules/SectionTitle/SectionTitle'
import If from 'components/atoms/If'
import Description from 'components/atoms/Description'

import './TitleDescription.scss'

function TitleDescription({ title, description, text, className, fullRow, sectionTitle }) {
  return (
    <div className={`title-description ${className}`}>
      {sectionTitle && (
        <Row>
          <Col lg="3" className="mb-4">
            <SectionTitle title={sectionTitle} textColor="gray-100" />
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <ContentBlock>
            <If condition={title}>
              <Typography variant="h2" cssClass="title-semibold-lg title-text">
                {title}
              </Typography>
            </If>
          </ContentBlock>
        </Col>
      </Row>
      {description && (
        <Row>
          <Col xs={12} md={fullRow ? 12 : 8} className="description-text mt-2">
            <Description className={className}>{description}</Description>
          </Col>
        </Row>
      )}
      {text && (
        <Row>
          <Col xs={12} md={fullRow ? 12 : 8} className="number-text mt-2">
            {text}
          </Col>
        </Row>
      )}
    </div>
  )
}

TitleDescription.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  fullRow: PropTypes.bool, //Whether should be 12 col on md
  text: PropTypes.string,
  title: PropTypes.string,
  sectionTitle: PropTypes.string,
}

export default TitleDescription
