import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import HalfBanner from 'components/molecules/HalfBanner'
import Container from 'react-bootstrap/Container'
import PropTypes from 'prop-types'
import ContentBlock from 'components/atoms/ContentBlock'
import Typography from 'components/atoms/Typography'
import Description from 'components/atoms/Description'
import ReactHtmlParser from 'react-html-parser'
import get from 'lodash.get'
import { userCheckMobileScreen } from 'helpers/tools'
import './HalfBanners.scss'

function HalfBanners({ data }) {
  const title = get(data, 'title')
  const responsiveImage = userCheckMobileScreen()
    ? 'imageBackground.entity.images.large.url'
    : 'imageBackground.entity.images.wide.url'
  const id = get(data, 'id')
  const description = get(data, 'description.value')
  const themeClasses = get(data, 'theme.entity.classes')
  const items = get(data, 'items')
  const additionalInformation = get(data, 'additionalInformation.value')

  return (
    <Container id={id} fluid="fluid" className={`half-banners ${themeClasses || 'bg-white'} section-spacing`}>
      <Container>
        {title && (
          <Row>
            <Col bgcolor="primary" xs={12}>
              <ContentBlock>
                <Typography variant="h3" cssClass="title mb-3 title-semibold-md">
                  {title}
                </Typography>

                {description && (
                  <Description className="mb-4">
                    <Typography variant="span" color="gray-100" cssClass="bloco-conteudo-text">
                      {ReactHtmlParser(description)}
                    </Typography>
                  </Description>
                )}
              </ContentBlock>
            </Col>
          </Row>
        )}

        <Row>
          {Array.isArray(items) &&
            items.map(({ entity }, index) => (
              <Col
                xs={12}
                lg={6}
                key={get(entity, 'uuid', index)}
                className={`${get(entity, responsiveImage) && 'mb-3'} mb-lg-1 mt-1`}
              >
                <HalfBanner
                  buttons={get(entity, 'buttons').map(({ entity }) => ({
                    id: get(entity, 'id'),
                    icon: get(entity, 'linkType.entity.token'),
                    text: get(entity, 'link.title'),
                    url: get(entity, 'link.url.path'),
                    key: get(entity, 'link.title'),
                    linkType: get(entity, 'linkType.entity.name'),
                  }))}
                  id={get(entity, 'id')}
                  theme={get(entity, 'theme.entity.classes')}
                  description={get(entity, 'description.value')}
                  icon={get(entity, 'icon.entity.media.entity.fieldMediaFile.entity.url')}
                  imageBackground={get(entity, responsiveImage)}
                  orientation="half-banner-col"
                  title={get(entity, 'title')}
                />
              </Col>
            ))}
        </Row>

        {additionalInformation && (
          <Row>
            <Col md={10}>
              <div className={`half-banners-additional-info text-secondary`}>
                {ReactHtmlParser(additionalInformation)}
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </Container>
  )
}

HalfBanners.propTypes = {
  data: PropTypes.object.isRequired,
}

export default HalfBanners
