import { GET_PIN_UTILIZATION, GET_RECIPIENTS_DATE } from 'store/constants'

export function getPinSSUtilization(values) {
  return {
    type: GET_PIN_UTILIZATION,
    payload: values,
  }
}

export function getRecipientsDate(values) {
  return {
    type: GET_RECIPIENTS_DATE,
    payload: values,
  }
}
