import React from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import './PaginationButton.scss'

function PaginationButton({ onClick, active, page, url, text, disabled, isPrevious, isNext }) {
  const history = useHistory()

  function handleClick() {
    if (url) history.push(url)
    if (onClick) onClick(page)
  }

  return (
    <button
      disabled={disabled}
      className={`PaginationButton pagination-btn ${active ? 'active' : ''} 
        ${isPrevious ? 'pagination-btn-previous' : ''} ${isNext ? 'pagination-btn-next' : ''} ${
        disabled ? 'disabled' : ''
      }`}
      onClick={handleClick}
    >
      {isPrevious && <i className="icone-chevron-left"></i>}
      {text}
      {isNext && <i className="icone-chevron-right"></i>}
    </button>
  )
}

PaginationButton.propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  isPrevious: PropTypes.bool,
  isNext: PropTypes.bool,
  onClick: PropTypes.func,
  page: PropTypes.number,
  url: PropTypes.string,
  text: PropTypes.string,
}

export default PaginationButton
