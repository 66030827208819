import React from 'react'

import './Iframe.scss'

function Iframe(props) {
  const { height, className } = props
  const style = height ? { height: `calc(100vh - ${height}px)` } : { height: `calc(100vh)` }

  return (
    <iframe
      className={`iframe ${className}`}
      name={props.name}
      id={props.id}
      src={props.src}
      width="100%"
      scrolling="auto"
      style={style}
    />
  )
}

export default Iframe
