import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { get } from 'lodash'

import Accordion from 'components/molecules/Accordion/Accordion'
import TitleDescription from 'components/molecules/TitleDescription/TitleDescription'

import './ServiceWidget.scss'

function ServiceWidget({ data }) {
  const themeClasses = get(data, 'theme.entity.classes')

  return (
    <Container fluid className={`service-widget ${themeClasses}`}>
      <Container className="pb-5 pt-4">
        <TitleDescription
          className="service-width__title-description mb-4"
          title={get(data, 'title')}
          description={get(data, 'description.value')}
        />
        {data.services.map(({ entity }) => (
          <Accordion
            key={entity.uuid}
            type="faq-2"
            items={[
              {
                name: get(entity, 'title'),
                text: get(entity, 'body.value'),
              },
            ]}
          />
        ))}
      </Container>
    </Container>
  )
}

export default ServiceWidget
