import React, { useEffect } from 'react'
import get from 'lodash.get'
import { Container } from 'react-bootstrap'
import TitleDescription from 'components/molecules/TitleDescription'
import Flipbook from 'components/organisms/Flipbook'
import dataGa from 'helpers/dataGa'

const Download = (props) => {
  const { data, user, refs } = props
  const entity = get(data, 'route.entity') || { content: [] }
  const title = get(entity, 'title')
  useEffect(() => {
    dataGa(entity.dataGa)
  }, [])

  return (
    <>
      <Container className="page-faqs section-spacing-first">
        <Container>
          <TitleDescription title={title} first={true} refs={refs} />
        </Container>
      </Container>
      <Flipbook data={entity} />
    </>
  )
}

export default Download
