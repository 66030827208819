import React, { useEffect, useState } from 'react'
import { get } from 'lodash'
import { Col, Container, Row } from 'react-bootstrap'
import { userCheckMobileScreen } from 'helpers/tools'
import GridGallery from 'components/molecules/GridGallery'
import TitleDescription from 'components/molecules/TitleDescription'

import './ImagesMosaic.scss'

function ImagesMosaic({ data }) {
  const [isMobile, setIsMobile] = useState(false)
  const { items, medias } = data
  useEffect(() => {
    setIsMobile(userCheckMobileScreen())
  }, [])

  return (
    <div className="images-mosaic section-spacing">
      <Container>
        <Row>
          <Col lg={6}>
            {items?.map(({ entity }, index) => (
              <TitleDescription
                key={entity?.uuid}
                className={`${get(entity, 'theme.entity.classes')} ${data.items.length !== index + 1 && 'mb-4'}`}
                description={get(entity, 'description.value')}
                title={entity.title}
                fullRow
              />
            ))}
          </Col>
          <Col className="ml-3" lg={5}>
            <GridGallery
              items={medias?.map(({ entity }) => ({
                src: isMobile ? entity.images.medium.url : entity.images.wide.url,
              }))}
              className="images-mosaic__grid-gallery"
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ImagesMosaic
