import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import PaginationButton from 'components/atoms/PaginationButton'
import './Pagination.scss'

function Pagination({ className, onChangePage, totalPages, paginationOffset, initialPage, useQueryParams }) {
  const pages = new Array(totalPages || 1).fill(1)
  const history = useHistory()
  const query = new URLSearchParams(history.location.search)

  const [currentPage, setCurrentPage] = useState(initialPage + 1)
  const [startPage, setStartPage] = useState(initialPage - paginationOffset)
  const [lastPage, setLastPage] = useState(initialPage + paginationOffset)

  useEffect(() => {
    setCurrentPage(initialPage + 1)
    setStartPage(initialPage - paginationOffset)
    setLastPage(initialPage + paginationOffset)
  })

  const handlePageChange = (page) => {
    page = parseInt(page)

    let first = page - paginationOffset
    first = first > 0 ? first : 1
    let last = page + paginationOffset - 1
    last = last <= totalPages ? last : totalPages

    setCurrentPage(page)
    setStartPage(first > 0 ? first : 1)
    setLastPage(last)

    if (onChangePage) onChangePage(parseInt(page))
  }

  const changeQueryPage = (page) => {
    query.delete('page')
    query.append('page', page.toString())
    return query.toString()
  }

  const previousPageUrl = `${history.location.pathname}?${changeQueryPage(parseInt(currentPage) - 1)}`
  const nextPageUrl = `${history.location.pathname}?${changeQueryPage(parseInt(currentPage) + 1)}`
  query.delete('page')

  return (
    <div className={className}>
      <section className={`Pagination pagination-container`}>
        <PaginationButton
          onClick={handlePageChange}
          page={currentPage - 1}
          text={'Anterior'}
          disabled={currentPage - 1 <= 0}
          url={useQueryParams && previousPageUrl}
          isPrevious
        />

        {pages.map((_, index) => {
          const page = index + 1
          const url = history.location.pathname + '?' + changeQueryPage(page)

          if (page >= startPage && page <= lastPage) {
            return (
              <PaginationButton
                key={index}
                onClick={handlePageChange}
                page={page}
                text={page.toString()}
                active={currentPage === page}
                url={useQueryParams && url}
              />
            )
          }
        })}

        <PaginationButton
          onClick={handlePageChange}
          page={currentPage + 1}
          text={'Próximo'}
          url={useQueryParams && nextPageUrl}
          disabled={currentPage === totalPages}
          isNext
        />
      </section>
    </div>
  )
}

Pagination.propTypes = {
  className: PropTypes.string,
  initialPage: PropTypes.number.isRequired,
  onChangePage: PropTypes.func,
  paginationOffset: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
}

export default Pagination
