import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

export function DFlip({ documentURL }) {
  return (
    <>
      <Helmet>
        <script src="/dflip/js/libs/jquery.min.js" type="text/javascript"></script>
        <script src="/dflip/js/dflip2.js" type="text/javascript"></script>
        <link href="/dflip/css/dflip.css" rel="stylesheet" type="text/css" />
        <link href="/dflip/css/themify-icons.css" rel="stylesheet" type="text/css" />
      </Helmet>
      <div
        backgroundcolor="#b1d34b"
        className="_df_book"
        id="df_manual_book"
        height="800"
        source={documentURL}
        webgl="true"
      />
    </>
  )
}

DFlip.propTypes = {
  documentURL: PropTypes.string,
}

export default DFlip
