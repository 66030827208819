import React from 'react'
import PropTypes from 'prop-types'
import ClassNames from 'classnames'
import get from 'lodash.get'
import SVG from 'react-inlinesvg'
import { startsWithHttp } from 'helpers/strings'

import Typography from 'components/atoms/Typography'
import CustomButton from 'components/atoms/CustomButton'

import './HalfBanner.scss'

function HalfBanner({ icon, buttons, className, description, id, imageBackground, orientation, theme, title }) {
  const backgroundImage = imageBackground && `url(${imageBackground})`

  return (
    <div
      id={id}
      className={ClassNames('half-banner card', className, orientation, {
        [`card-${theme}`]: theme,
        'd-none d-lg-flex': imageBackground,
      })}
      style={{ backgroundImage }}
    >
      {title && (
        <div className="card-cta-header mb-2">
          {icon && <SVG src={icon} className="card-cta-header__image" />}
          <Typography variant="h3" component="h6" cssClass={null}>
            {title}
          </Typography>
        </div>
      )}

      <Typography variant="p">{description}</Typography>

      <div className="card-cta-btn">
        {Array.isArray(buttons) &&
          buttons.map((button, index) => {
            const icon = get(button, 'icon')
            const align = (icon && icon.includes('arrow')) || !icon ? 'right' : 'left'
            const buttonUrl = get(button, 'url')
            const url =
              icon === 'email' && !buttonUrl?.includes('mailto:')
                ? 'mailto: ' + decodeURI(buttonUrl.replace('/', ''))
                : buttonUrl
            const color = theme === 'success' ? 'green-dark-200' : 'white'

            return (
              <CustomButton
                color={`outline-${theme ? color : 'primary'}`}
                className="mt-3 mr-md-3"
                id={get(button, 'id')}
                href={url}
                linkType={button?.linkType}
                icon={icon}
                iconAlign={align}
                target={startsWithHttp(url) ? '_blank' : ''}
                text={get(button, 'text')}
                key={(get(button, 'id') || url) + index}
              />
            )
          })}
      </div>
    </div>
  )
}

HalfBanner.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  orientation: PropTypes.string,
  imageBackground: PropTypes.string,
  icon: PropTypes.string,
  theme: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.string,
      icon: PropTypes.string,
      link: PropTypes.string,
      linkType: PropTypes.string,
    })
  ),
}

export default HalfBanner
