import React from "react"
import Button from "react-bootstrap/Button"

function ButtonOutline(props) {
  return (
    <Button variant="outline-primary" size="lg" onClick={props.onClick}>
      {props.text}
    </Button>
  );
}

export default ButtonOutline
