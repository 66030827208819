import React, { useEffect, useState, Fragment } from 'react'
import { get, orderBy } from 'lodash'
import { Container } from 'react-bootstrap'
import classNames from 'classnames'

import CustomButton from 'components/atoms/CustomButton'
import ComboBox from 'components/molecules/ComboBox'
import TitleDescription from 'components/molecules/TitleDescription/TitleDescription'

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import { useHistory } from 'react-router-dom'

import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import './DefinitionWidget.scss'

function DefinitionWidget({ data }) {
  const { category, last, first, theme } = data
  const history = useHistory()
  const [definitions, setDefinitions] = useState(Array(get(data, 'category[0]')))
  const [filters, setFilters] = useState(category?.map(({ entity }) => ({ name: entity.name, value: entity.tid })))

  const handleFilter = (filterData) => {
    const filtered = Object.values(data?.category).filter((el) => filterData.value === el.entity.tid)
    setFilters(filters?.map((filter) => ({ ...filter, selected: filter.value === filterData.value })))
    setDefinitions(filtered.length ? filtered : Array(get(data, 'category[0]')))
    window.history.pushState(null, null, `${history.location.pathname + '#' + encodeURI(filterData.name)}`)
  }

  useEffect(() => {
    const hash = decodeURI(history.location.hash)?.replace('#', '') || category[0].entity.name
    const filterByHashUrl = category?.find(({ entity }) => entity.name === hash)

    setDefinitions(filterByHashUrl ? Array(filterByHashUrl) : Array(get(data, 'category[0]')))
    setFilters(category?.map(({ entity: { name, tid } }) => ({ name, value: tid, selected: hash === name })))
  }, [history])

  return (
    <Container
      fluid
      className={classNames('definition-widget section-spacing', get(theme, 'entity.classes'), {
        'section-spacing-last': last,
        'section-spacing-first': first,
      })}
    >
      <Container>
        <TitleDescription className="mb-4" title={get(data, 'title')} description={get(data, 'description.value')} />
      </Container>
      <Container>
        <div className="d-block d-lg-none mb-1 mt-3">
          <ComboBox
            type="link"
            label="Todas as categorias: "
            options={filters}
            selected={filters?.find(({ selected }) => selected)?.value}
            onChange={handleFilter}
          />
        </div>
        <div className="d-none d-lg-flex mb-3">
          {filters?.map((filter, index) => (
            <CustomButton
              key={get(filter, 'value')}
              color="primary"
              text={filter.name}
              onClick={() => handleFilter(filter, index)}
              noIcon
              className={classNames('mr-2', { active: filter.selected })}
            />
          ))}
        </div>
      </Container>
      <Container>
        <Table className="definition-widget__table mb-5">
          <Thead>
            <Tr>
              <Th>Item</Th>
              <Th>Condição</Th>
              <Th>Situação</Th>
              <Th>Revisão</Th>
              <Th>Vigência</Th>
            </Tr>
          </Thead>
          <Tbody>
            {definitions?.map((category) =>
              orderBy(get(category, 'entity.definitions.entities'), 'created', 'desc')
                .filter((a) => a)
                .map((entity, index) => {
                  const documentPath = get(entity, 'media.entity.fieldMediaDocument.entity.url')
                  const extension = documentPath?.split('.').pop()
                  const documentUrl = get(entity, 'url.path')

                  return (
                    <Fragment key={`definitions=${documentUrl}-${index}`}>
                      <Tr key={index + 1}>
                        <Td>
                          {extension == 'pdf' ? (
                            <a href={documentPath} target="_blank" rel="noopener noreferrer">
                              {get(entity, 'title')}
                            </a>
                          ) : (
                            <a href={documentUrl} rel="noopener noreferrer">
                              {get(entity, 'title')}
                            </a>
                          )}
                        </Td>
                        <Td>{get(entity, 'condition')}</Td>
                        <Td>{get(entity, 'situation')}</Td>
                        <Td>{get(entity, 'revision')}</Td>
                        <Td>{get(entity, 'validity')}</Td>
                      </Tr>
                    </Fragment>
                  )
                })
            )}
          </Tbody>
        </Table>
      </Container>
    </Container>
  )
}

export default DefinitionWidget
