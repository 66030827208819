import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'
import { Spinner } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Iframe from 'components/atoms/Iframe'
import ButtonOutline from 'components/atoms/ButtonOutline'
import TitleDescription from 'components/molecules/TitleDescription/TitleDescription'
import LinkHandler from 'components/atoms/LinkHandler'

function ExternalApplication(props) {
  const dispatch = useDispatch()
  const { data, user } = props
  const id = get(data, 'uuid')
  const redirect = get(data, 'redirect')
  const title = get(data, 'title')
  const description = get(data, 'description.value')
  const formAction = get(data, 'url')
  const formMethod = get(data, 'formMethod')
  const type = get(data, 'type') ?? 'form'
  const access_token = useSelector((state) => state.token?.token?.access_token)
  const access_token_loading = useSelector((state) => state.auth.loadingUser)
  const [submittedForm, setSubmittedForm] = useState(false)

  const handleSubmit = () => {
    setSubmittedForm(true)
    handleLoad()
  }
  const handleLoad = () => {
    const form = document.getElementById(`form-external-${id}`)
    form.submit()
    setSubmittedForm(false)
  }

  useEffect(() => {
    if (submittedForm && !access_token_loading && access_token) {
      handleLoad()
    }
  }, [access_token, access_token_loading])

  useEffect(() => {
    if (!redirect) {
      handleLoad()
    }
  }, [])

  return (
    <Container fluid="fluid" className="my-3">
      <Container>
        <Row>
          {type === 'form' && (
            <Col className="my-3">
              <form
                action={formAction}
                id={`form-external-${id}`}
                method={formMethod ?? 'post'}
                target={redirect ? '_blank' : `frame-external-${id}`}
              >
                <input type="hidden" name="TOKEN" value={access_token} />
              </form>
              {redirect ? (
                <>
                  <TitleDescription title={title} description={description} className="primary" />
                  <br />
                  <ButtonOutline
                    text={access_token_loading ? <Spinner size="sm" animation="border" /> : `Acessar`}
                    onClick={handleSubmit}
                  />
                </>
              ) : (
                <Iframe id={`frame-external-${id}`} name={`frame-external-${id}`} />
              )}
            </Col>
          )}
          {type === 'link' && (
            <Col className="my-3">
              <TitleDescription title={title} description={description} className="primary" />
              <br />
              <LinkHandler
                className="d-flex btn btn-outline-primary rounded-pill"
                to={formAction}
                title={title}
                target="_blank"
              >
                Acessar
              </LinkHandler>
            </Col>
          )}
        </Row>
      </Container>
    </Container>
  )
}

export default ExternalApplication
