import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { get, union, orderBy, groupBy } from 'lodash'
import { useHistory } from 'react-router-dom'
import TitleDescription from 'components/molecules/TitleDescription/TitleDescription'
import Pagination from 'components/molecules/Pagination'
import CustomButton from 'components/atoms/CustomButton'
import Typography from 'components/atoms/Typography/Typography'
import { userCheckMobileScreen } from 'helpers/tools'

import './ContentList.scss'

function ContentList({ data }) {
  const history = useHistory()
  const hash = decodeURI(history.location.hash)?.replace('#', '')
  const [currentPage, setCurrentPage] = useState(0)

  const pages = data.pages.map(({ entity }) => ({
    ...entity,
    year: new Date(entity.created * 1000).getFullYear(),
  }))
  const ordered = orderBy(pages, ['created'], ['desc'])
  const grouped = groupBy(ordered, 'year')

  const [filters, setFilters] = useState(() =>
    orderBy(
      Object.keys(grouped).map((el) => {
        return { name: el, value: el, selected: hash === el }
      }),
      ['value'],
      ['desc']
    )
  )
  const yearsList = orderBy(
    Object.keys(grouped).map((value) => ({
      year: value,
      items: grouped[value],
    })),
    'year',
    'desc'
  )
  const filterByHashUrl = yearsList.find((el) => el.year === hash)
  const [content, setContent] = useState(() => (filterByHashUrl ? Array(filterByHashUrl) : Array(yearsList[0])))

  const onfilter = (filterData) => {
    const filtered = yearsList.filter((el) => filterData.value == el.year)

    let temp = filters.map((el) => {
      return { ...el, selected: el.value === filterData.value }
    })

    setFilters(temp)
    setContent(filtered.length ? filtered : Array(yearsList[0]))
    window.history.pushState(null, null, `${history.location.pathname + '#' + encodeURI(filterData.name)}`)
    setCurrentPage(0)
  }

  const contentList = data.grouped ? content[0]?.items : ordered
  const itemsLimit = 10
  const offset = currentPage * itemsLimit
  const lastPosition = currentPage * itemsLimit + itemsLimit
  const contentListPaginated = contentList?.slice(offset, lastPosition)

  if (filters?.length > 0 && !filters.find(({ selected }) => selected === true)) {
    filters[0].selected = true
  }

  return (
    <Container
      fluid
      className={`content-list section-spacing${data.last ? '-last' : ''} ${get(data, 'theme.entity.classes')}`}
    >
      <Container>
        <TitleDescription className="mb-4" title={get(data, 'title')} description={get(data, 'description.value')} />
      </Container>
      {data.grouped && (
        <Container>
          <div className="d-none d-lg-flex flex-wrap mt-4 mb-3">
            {filters.map((filter, index) => {
              return (
                <CustomButton
                  key={get(filter, 'value')}
                  color={`primary`}
                  text={filter.name}
                  onClick={() => onfilter(filter, index)}
                  noIcon
                  className={`mr-2 my-2 ${filter.selected ? 'active' : ''}`}
                />
              )
            })}
          </div>
        </Container>
      )}
      <Container>
        {contentListPaginated?.map((entity, index) => {
          const documentPath = get(entity, 'media.entity.fieldMediaDocument.entity.url')
          const extension = documentPath?.split('.').pop()
          const documentUrl = get(entity, 'url.path')
          const documentName = get(entity, 'media.entity.fieldMediaDocument.entity.name')

          if (entity) {
            return (
              <Row key={index} className="content-list__row py-3 mx-0">
                <Col sm={12} lg={8} className="mb-3 mb-lg-0 px-0">
                  <Typography className="text">{get(entity, 'title')}</Typography>
                </Col>
                {((extension?.toLowerCase() === 'pdf' && data.viewPdf) || get(entity, 'url.path')) && (
                  <Col xs={6} lg={2} className="px-0">
                    <a href={documentUrl || get(entity, 'url.path')} rel="noopener noreferrer" target="_blank">
                      <Typography cssClass="d-md-flex justify-content-end align-items-center">
                        <i className="icone-eye mr-1" />
                        Visualizar
                      </Typography>
                    </a>
                  </Col>
                )}
                {documentPath && (
                  <Col xs={6} lg={2} className="px-0">
                    <a href={documentPath} target="_blank" download={documentName} rel="noopener noreferrer">
                      <Typography cssClass="d-md-flex justify-content-end align-items-center">
                        <i className="icone-download mr-1" />
                        Download
                      </Typography>
                    </a>
                  </Col>
                )}
              </Row>
            )
          }
        })}
        <Pagination
          initialPage={currentPage}
          totalPages={Math.ceil(contentList?.length / itemsLimit)}
          onChangePage={(page) => setCurrentPage(page - 1)}
          paginationOffset={userCheckMobileScreen() ? 1 : 3}
        />
      </Container>
    </Container>
  )
}

export default ContentList
