import React, { useEffect, useState } from 'react'
import { get } from 'lodash'

import DFlip from 'components/molecules/DFlip'

function Flipbook({ data }) {
  const [documentURL, setDocumentURL] = useState('')

  useEffect(() => {
    setDocumentURL(get(data, 'media.entity.fieldMediaDocument.entity.url'))
  }, [])

  if (documentURL) return <DFlip documentURL={documentURL} />
  return null
}

export default Flipbook
