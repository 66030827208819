import React, { useState } from 'react'
import { flatten, get, orderBy, groupBy } from 'lodash'
import { Col, Container, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

import CustomButton from 'components/atoms/CustomButton'
import TitleDescription from 'components/molecules/TitleDescription/TitleDescription'
import Accordion from 'components/molecules/Accordion'
import ComboBox from 'components/molecules/ComboBox'
import Pagination from 'components/molecules/Pagination'
import { userCheckMobileScreen } from 'helpers/tools'

import './GlossaryWidget.scss'

function parseDateGlossaries(param) {
  return param.map(({ entity }) => {
    return entity.glossaries.entities.map((d) => {
      if (d && d.created) {
        let dateObject = new Date(d.created * 1000)
        d.day = dateObject.getDay()
        d.month = dateObject.getMonth()
        d.year = dateObject.getFullYear()
      }
      return d
    })
  })
}

function GlossaryWidget({ data }) {
  const [currentPage, setCurrentPage] = useState(0)
  const history = useHistory()
  const hash = decodeURI(history.location.hash)?.replace('#', '')
  const glossariesParseDate = parseDateGlossaries(get(data, 'category') || [])
  const glossariesGrouped = data.grouped ? groupBy(flatten(glossariesParseDate), 'year') : glossariesParseDate
  const [filters, setFilters] = useState(
    orderBy(
      Object.keys(glossariesGrouped).map((el) => ({ name: el, value: el, selected: hash === el })),
      ['value'],
      ['desc']
    )
  )

  const parseGlossary = orderBy(
    Object.keys(glossariesGrouped).map((value) => ({ year: value, items: glossariesGrouped[value] })),
    ['year'],
    ['desc']
  )
  const filterByHashUrl = parseGlossary.find((el) => el.year === hash)
  const [glossaries, setGlossaries] = useState(() => {
    if (filterByHashUrl && data.grouped) {
      return Array(filterByHashUrl)
    }
    if (parseGlossary.length > 0) return Array(parseGlossary[0])
  })

  const itemsLimit = 10
  const offset = currentPage * itemsLimit
  const lastPosition = currentPage * itemsLimit + itemsLimit
  const glossariesPaginated = glossaries?.map(({ items, year }) => {
    const orderedItems = orderBy(items, 'created', 'desc')
    return {
      paginated: orderedItems.slice(offset, lastPosition),
      items,
      year,
    }
  })

  const onFilter = (filterData) => {
    const filtered = parseGlossary.filter((el) => filterData.value == el.year)
    const temp = filters.map((el) => ({ ...el, selected: el.value === filterData.value }))

    setFilters(orderBy(temp, ['value'], ['desc']))
    setGlossaries(filtered.length ? filtered : Array(parseGlossary[0]))
    window.history.pushState(null, null, `${history.location.pathname + '#' + encodeURI(filterData.name)}`)
    setCurrentPage(0)
  }

  // Set first filter has default
  if (!filters.find(({ selected }) => selected === true) && filters.length > 0) {
    filters[0].selected = true
  }

  return (
    <Container id={data.id} fluid="fluid" className="glossary-widget bg-white my-4">
      <Container>
        <TitleDescription title={get(data, 'title')} description={get(data, 'description.value')} />
      </Container>
      <Container>
        <div className="d-block d-lg-none mb-1">
          <ComboBox
            type="link"
            label="Todas as categorias: "
            options={filters}
            selected={filters.find((a) => a.selected)?.value}
            onChange={onFilter}
          />
        </div>
        {data.grouped && (
          <div className="d-none d-lg-flex flex-wrap mb-3">
            {filters.map((filter, index) => {
              return (
                <CustomButton
                  key={get(filter, 'value')}
                  color={`primary`}
                  text={filter.name}
                  onClick={() => onFilter(filter, index)}
                  noIcon
                  className={`mr-2 my-2 ${filter.selected ? 'active' : ''}`}
                />
              )
            })}
          </div>
        )}
        <Row>
          <Col>
            {glossariesPaginated?.map(({ items, paginated }, index) => (
              <div key={`glossaries-${index}`}>
                <Accordion type="faq-2" items={orderBy(paginated, 'created', 'desc')} className="faq-list-accordion" />
                <Pagination
                  initialPage={currentPage}
                  totalPages={Math.ceil(items.length / itemsLimit)}
                  onChangePage={(page) => setCurrentPage(page - 1)}
                  paginationOffset={userCheckMobileScreen() ? 1 : 3}
                />
              </div>
            ))}
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default GlossaryWidget
