import React, { useEffect, useState } from 'react'
import JsxParser from 'react-jsx-parser'
import ReactStringReplace from 'react-string-replace'
import ElementToJsxString from 'react-element-to-jsx-string'
import ReactHtmlParser from 'react-html-parser'
import LinkHandler from 'components/atoms/LinkHandler'
import Table from 'components/molecules/Table'
import Image from 'components/atoms/Image'

import './Description.scss'

function Description({ className, children }) {
  const [parsedText, setParsedText] = useState('')
  const parsedChildren = typeof children !== 'object' ? ReactHtmlParser(children) : children

  function getAllAtributes(element) {
    let attributes = {}
    for (var key in element.attributes) {
      if (element.attributes[key].value) {
        attributes[element.attributes[key].name] = element.attributes[key].value
      }
    }
    return attributes
  }

  function getStyles(styles = '') {
    let style = {}
    styles.split(';').map((content) => {
      if (content) {
        const name = content.split(':')[0]
        const value = content.split(':')[1]
        style[name] = value.trim()
      }
    })
    return style
  }

  function replaceTable(match) {
    const template = document.createElement('template')
    template.innerHTML = match.trim()
    const table = template.content.firstChild
    const { align, border, cellpadding, cellspacing, style } = getAllAtributes(table)

    // get th element's content and its attributes
    const headers = [...table.querySelectorAll('th')].map((element) => {
      const props = getAllAtributes(element)
      const style = getStyles(props.style)
      return { content: element.textContent, ...props, style }
    })

    const bodyContent = [...table.querySelectorAll('tbody tr')].map(({ children }) => {
      return [...children].map((td) => {
        const props = getAllAtributes(td)
        const style = getStyles(props.style)
        return { content: td.textContent, ...props, style }
      })
    })

    return `
      <div className="description__table">
        <Table
          align="${align}"
          bodyContent={${JSON.stringify(bodyContent)}}
          border={${border || 1}}
          cellPadding={${cellpadding}}
          cellSpacing={${cellspacing}}
          className="my-3"
          headers={${JSON.stringify(headers)}}
          style="${style}"
          key="description-table-1"
        />
      </div>`
  }

  function replaceLink(match) {
    const element = document.createElement('a')
    element.innerHTML = match
    const href = element?.firstChild?.getAttribute('href')?.trim()
    const text = element?.firstChild?.textContent
    return `<LinkHandler className="description__link" to="${href}">
        ${text}
      </LinkHandler>`
  }

  function replaceImage(match) {
    const element = document.createElement('img')
    element.innerHTML = match
    const src = element?.firstChild?.getAttribute('src')?.trim()
    const height = element?.firstChild?.getAttribute('height')?.trim()
    const width = element?.firstChild?.getAttribute('width')?.trim()
    return `<Image lazyloadClassName="description__image" src="${src}" height="${height}" width="${width}" openOnNewTab />`
  }

  useEffect(() => {
    let textReplaced = typeof children === 'string' ? children?.replace(/\s\s+/g, '') : children // Remove tabs, newlines, etc
    textReplaced = ReactStringReplace(textReplaced, /(<a[^>]*>.*?<\/a>)/g, replaceLink)
    textReplaced = ReactStringReplace(textReplaced, /(<table[^>]*>(?:.|\n)*?<\/table>)/g, replaceTable)
    textReplaced = ReactStringReplace(textReplaced, /(<img[^>]*>)/g, replaceImage)
    textReplaced = textReplaced.map((item) => (typeof item === 'object' ? ElementToJsxString(item) : item)).join('')
    setParsedText(textReplaced)
  }, [])

  return (
    <div className={`description ${className}`} data-testid="description">
      {parsedText ? <JsxParser jsx={parsedText} components={{ Image, Table, LinkHandler }} /> : parsedChildren}
    </div>
  )
}

export default Description
