import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import TitleDescription from 'components/molecules/TitleDescription'
import get from 'lodash.get'
import { renderIndexItemClass } from 'helpers/tools'

import './TitleAndDescription.scss'

function TitleAndDescription(props) {
  const { data } = props
  const title = get(data, 'title')
  const description = get(data, 'description.value')
  const text = get(data, 'text')
  const themeClasses = get(data, 'theme.entity.classes')
  const firstItemRender = get(data, 'first')
  const lastItemRender = get(data, 'last')
  const sectionTitle = get(data, 'sectionTitle')

  return (
    <Container
      fluid="fluid"
      className={`title-and-description ${themeClasses} ${renderIndexItemClass(firstItemRender, lastItemRender)}`}
    >
      <Container>
        <TitleDescription
          sectionTitle={sectionTitle}
          title={title}
          description={description}
          text={text}
          className={themeClasses}
        />
      </Container>
    </Container>
  )
}

export default TitleAndDescription
