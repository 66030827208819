import React, { useEffect } from 'react';
import { get, isEmpty, isEqual } from 'lodash';
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import ButtonOutline from 'components/atoms/ButtonOutline';
import Typography from 'components/atoms/Typography';
import { useDispatch, useSelector } from 'react-redux'
import TitleDescription from 'components/molecules/TitleDescription'
import * as atosActions from 'store/modules/atos/actions'

function AtosApplication(props) {
    const { data, user } = props;
    const dispatch = useDispatch()

    let session = useSelector(state => state?.auth?.user, isEqual)

    const atosLoginData = useSelector(state => state.atos.atosLoginData)
    const atosLoginError = useSelector(state => state.atos.atosLoginError)
    const atosLoginLoading = useSelector(state => state.atos.atosLoginLoading)

    useEffect(() => {
        if (isEmpty(atosLoginData) && !atosLoginError) {
            dispatch(atosActions.getAtosLoginData({ user: session?.contract }))
        }
    }, [])

    const title = get(data, 'title', '');
    const descriptionObj = get(data, 'description');
    const description = get(descriptionObj, 'value', '');
    const url = get(data, 'url', '');

    const fnClick = () => {
        const userCode = get(atosLoginData, 'COD_USUARIO')
        const userKey = get(atosLoginData, 'CHAVE')
        let atosUrl = `${process.env.RAZZLE_ATOS_BASE_URL}/sso/access/chave/signin.do?`
        atosUrl += 'codigoUsuario=' + userCode + '&tipo=0&chaveUsuario=' + userKey
        atosUrl += '&redirectBackTo=' + encodeURIComponent(process.env.RAZZLE_ATOS_BASE_URL + url)
        window.open(atosUrl, '_blank')
    }

    return <>
        <Container>
            <Row className="my-5">
                <Col>
                    <TitleDescription title={title} description={description} className="mb-2" />
                    {atosLoginLoading && <Spinner animation="border" size="sm" />}
                    {!atosLoginLoading && get(atosLoginData, 'COD_USUARIO') && <ButtonOutline text="Acessar" onClick={fnClick} />}
                    {!atosLoginLoading && isEmpty(atosLoginData) && <Typography variant="p" color="danger">Você não tem acesso a este recurso</Typography>}
                </Col>
            </Row>
        </Container>
    </>;
}

export default AtosApplication;