import React, { useEffect, useState, useContext } from 'react'
import { Container, Form, Row, Col, Button } from 'react-bootstrap'
import { useDispatch, useSelector, ReactReduxContext } from 'react-redux'
import get from 'lodash/get'
import { DateTime } from 'luxon'
import { Spinner } from 'react-bootstrap'
import { printElement } from 'helpers/tools'

import * as pinSSUtilization from 'store/modules/pinSSUtilization/actions'
import Table from 'components/molecules/Table'
import TitleDescription from 'components/molecules/TitleDescription'
import Breadcrumb from 'components/atoms/Breadcrumb'

import './PinSSComponenteDeUtilizacao.scss'

export const headers = [
  'Razão Social ou nome do prestador',
  'Cartão Nacional de Saúde',
  'Número de identificação do plano do beneficiário na ANS',
  'Nome do plano de saúde do beneficiário',
  'Data de realização ou data inicial do período de atendimento',
  'Descrição do caráter do atendimento',
  'Código do procedimento realizado ou item assistencial utilizado',
  'Descrição do procedimento realizado ou item assistencial utilizado',
  'Quantidade executada',
  'Valor pago',
]

export const headersFull = [
  'Código no Cadastro Nacional de Estabelecimentos de Saúde do executante',
  'Número de cadastro do prestador executante na Receita Federal',
  'Razão social ou nome do prestador',
  'Município de localização do prestador',
  'Nome do município de localização do prestador',
  'Número da carteira do beneficiário',
  'Nome do beneficiário',
  'Cartão Nacional de Saúde',
  'Número de identificação do plano do beneficiário na ANS',
  'Nome do plano de saúde do beneficiário',
  'Descrição do tipo de evento de atenção à saúde',
  'Data de realização ou data inicial do período de atendimento',
  'Data final do período de atendimento',
  'Descrição do tipo de consulta',
  'Código na Classificação Brasileira de Ocupações do executante',
  'Descrição da ocupação do executante',
  'Descrição do caráter do atendimento',
  'Descrição do tipo de Internação',
  'Descrição do tipo de atendimento',
  'Número de diárias de acompanhante',
  'Número de diárias de UTI',
  'Descrição do motivo de encerramento',
  'Código do grupo do procedimento ou item assistencial',
  'Código do procedimento realizado ou item assistencial utilizado',
  'Descrição do procedimento realizado ou item assistencial utilizado',
  'Descrição da identificação do dente ou região da boca',
  'Identificação da face do dente',
  'Quantidade executada',
  'Valor pago',
]

function PinSSComponenteDeUtilizacao({ data, user }) {
  const [date, setDate] = useState()
  const [validated, setValidated] = useState()
  const [simpleView, setSimpleView] = useState(true)

  const title = get(data, 'title')
  const description = get(data, 'description.value')
  const contract = get(user, 'contract')

  const dispatch = useDispatch()
  const { store } = useContext(ReactReduxContext)
  const pinSSResult = useSelector((state) => state.pinSSUtilization)
  const recipientsDateData = get(pinSSResult, 'recipientsDatesData.applications.pinSSData.recipientsDates.data')
  const recipientsDatesLoading = get(pinSSResult, 'recipientsDatesLoading')
  const recipientUsageData = get(pinSSResult, 'pinSSUtilizationData.applications.pinSSData.recipientUsage.data')
  const recipientUsageLoading = get(pinSSResult, 'pinSSUtilizationLoading')
  const recipientUsageError = get(pinSSResult, 'pinSSUtilizationError')
  const recipientErrorMessage = recipientUsageError && validated && getErrorMessage(recipientUsageError)

  const categories =
    recipientUsageData &&
    recipientUsageData
      .map(({ expense_category }) => expense_category)
      .filter((item, index, self) => self.indexOf(item) === index)

  function handleSubmit(e) {
    e.preventDefault()
    setValidated(true)

    if (date) {
      const dateFormated = date.split('/').reverse().join('-')
      dispatch(pinSSUtilization.getPinSSUtilization({ date: dateFormated, username: contract || '', store }))
    }
  }

  function getErrorMessage(error) {
    if (error.includes('No data found')) return 'Nenhum registro encontrado'
    if (error.includes('It is necessary enter the parameter username')) return 'Usuário inválido'
    return 'Algo deu errado na busca'
  }

  function getRecipients(recipientUsage, category) {
    return recipientUsage
      .filter(({ expense_category }) => category === expense_category)
      .map((data) =>
        simpleView
          ? {
            executor_name: data.executor_name,
            ans_register: data.ans_register,
            plan_register: data.plan_register,
            plan_name: data.plan_name,
            attendance_date: data.attendance_date,
            attendance_type: data.attendance_type,
            procedure_code: data.procedure_code,
            procedure_description: data.procedure_description,
            procedure_group: data.procedure_group,
            value_paid: data.value_paid,
          }
          : {
            executor_cnes: data.executor_cnes,
            executor_cnpj_cpf: data.executor_cnpj_cpf,
            executor_name: data.executor_name,
            executor_ibge_city_code: data.executor_ibge_city_code,
            executor_city_name: data.executor_city_name,
            recipient_code: data.recipient_code,
            recipient_name: data.recipient_name,
            cns_number: data.cns_number,
            plan_register: data.plan_register,
            plan_name: data.plan_name,
            authorization_type_guide_model: data.authorization_type_guide_model,
            date_hospitalization_service: data.date_hospitalization_service,
            discharge_date: data.discharge_date,
            appointment_type: data.appointment_type,
            executor_cbo_code: data.executor_cbo_code,
            executor_cbo_description: data.executor_cbo_description,
            service_caracter: data.service_caracter,
            hospitalization_type: data.hospitalization_type,
            attendance_type: data.attendance_type,
            companion_daily_quantity: data.companion_daily_quantity,
            uti_daily_quantity: data.uti_daily_quantity,
            discharge_reason: data.discharge_reason,
            procedure_group: data.procedure_group,
            procedure_code: data.procedure_code,
            procedure_description: data.procedure_description,
            tooth_identification: data.tooth_identification,
            tooth_face_identification: data.tooth_face_identification,
            quantity_paid: data.quantity_paid,
            value_paid: data.value_paid,
          }
      )
  }

  useEffect(() => {
    dispatch(pinSSUtilization.getRecipientsDate({ store }))
  }, [])

  return (
    <Container className="mt-5 pin-utilizacao px-3 mb-5" fluid>
      <TitleDescription title={title} className="mb-3" description={description} />
      <Form onSubmit={handleSubmit} noValidate validated={validated}>
        <Row>
          <Col xs={12} lg={4} className="my-3">
            <Form.Group>
              <Form.Label htmlFor="date">Período</Form.Label>
              <Form.Control
                as="select"
                value={date}
                name="date"
                id="date"
                onChange={({ target }) => setDate(target.value)}
                disabled={recipientsDatesLoading}
                isInvalid={validated && !date}
              >
                {recipientsDatesLoading ? (
                  <option value="">Carregando...</option>
                ) : (
                  <>
                    <option value="">Selecione o período</option>
                    {recipientsDateData &&
                      recipientsDateData.map(({ month_year }) => (
                        <option key={month_year} value={month_year}>
                          {month_year}
                        </option>
                      ))}
                  </>
                )}
              </Form.Control>
              <Form.Control.Feedback type="invalid">Campo obrigatório</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={4}>
            <Form.Group>
              <Form.Check
                className="pin-utilizacao__simple-result"
                type="checkbox"
                label="Geração simplificada"
                aria-label="Geração simplificada"
                checked={simpleView}
                onChange={({ target }) => setSimpleView(target.checked)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button className="mt-4 mb-5" variant="primary" size="lg" type="submit">
              Consultar
            </Button>
          </Col>
        </Row>
      </Form>

      <div className="pin-utilizacao__content">
        {recipientUsageData && (
          <Col className="pb-4 pin-utilizacao__user-info">
            <Row>
              <Col className="bg-gray-000 p-3" lg={3}>
                Operadora
              </Col>
              <Col className="bg-gray-400 p-3" lg={4}>
                UNIMED CURITIBA
              </Col>
              <Col className="bg-gray-000 p-3" lg={2}>
                ANS:
              </Col>
              <Col className="bg-gray-400 p-3" lg={3}>
                {get(recipientUsageData[0], 'ans_register')}
              </Col>
            </Row>
            <Row>
              <Col className="bg-gray-300 p-3" lg={3}>
                Nome
              </Col>
              <Col className="bg-gray-500 p-3" lg={4}>
                {get(recipientUsageData[0], 'recipient_name')}
              </Col>
            </Row>
            <Row>
              <Col className="bg-gray-000 p-3" lg={3}>
                Cartão
              </Col>
              <Col className="bg-gray-400 p-3" lg={4}>
                {get(recipientUsageData[0], 'cns_number')}
              </Col>
            </Row>
            <Row>
              <Col className="bg-gray-300 p-3" lg={3}>
                Período solicitado
              </Col>
              <Col className="bg-gray-500 p-3" lg={4}>
                {DateTime.fromJSDate(new Date(get(recipientUsageData[0], 'attendance_date'))).toLocaleString()}
              </Col>
            </Row>
          </Col>
        )}

        {recipientUsageLoading && (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" />
          </div>
        )}

        {!recipientUsageLoading && recipientErrorMessage && (
          <div className="d-flex justify-content-center">
            <p>{recipientErrorMessage} </p>
          </div>
        )}

        {categories &&
          categories.map((category) => (
            <>
              {!simpleView && (
                <Row>
                  <Col>Categoria: {category}</Col>
                </Row>
              )}

              <Row>
                <Col>
                  <Table
                    bodyContent={recipientUsageData && getRecipients(recipientUsageData, category)}
                    className="pin-utilizacao__table"
                    headers={simpleView ? headers : headersFull}
                    loading={recipientUsageLoading}
                  />
                </Col>
              </Row>
              <Row className="mb-4">
                <Col>
                  <span className="pin-utilizacao__category">
                    Categoria: {getRecipients(recipientUsageData, category).length}
                  </span>
                </Col>
              </Row>
            </>
          ))}
      </div>
      {recipientUsageData && (
        <Row className="mt-4">
          <Col className="d-flex justify-content-end">
            <Button className="mr-3" variant="outline-primary" size="lg" onClick={() => window.scrollTo(0, 0)}>
              Voltar
            </Button>
            {simpleView && (
              <Button variant="primary" size="lg" onClick={() => printElement('.pin-utilizacao__content', title)}>
                <i className="icone-printer mr-2" role="presentation"></i>
                Imprimir
              </Button>
            )}
          </Col>
        </Row>
      )}
    </Container>
  )
}

export default PinSSComponenteDeUtilizacao
