import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import Image from 'components/atoms/Image'
import ContentBlock from 'components/atoms/ContentBlock'
import Typography from 'components/atoms/Typography'
import LinkHandler from 'components/atoms/LinkHandler'
import { userCheckMobileScreen } from 'helpers/tools'
import get from 'lodash.get'
import SectionTitle from 'components/molecules/SectionTitle'
import Description from 'components/atoms/Description'

// Style
import './MiniBanner.scss'

function MiniBanner(props) {
  const history = useHistory()
  const { data } = props
  const image = get(data, 'media') || null
  const imageAlign = get(data, 'imageAlign') || 'left'
  const button = get(data, 'fieldLink') || null
  const themeClasses = get(data, 'theme.entity.classes')
  const { hide_mobile_image } = data
  const sectionTitle = get(data, 'sectionTitle')
  const description = get(data, 'description.value')
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    setIsMobile(userCheckMobileScreen())
  }, [])

  return (
    <Container id={props.data.id} fluid="fluid" className={`mini-banner ${themeClasses} pt-2`}>
      <Container>
        <Row>
          <Col sm={12} md={image ? 6 : 8} className={`${image && imageAlign === 'left' ? 'offset-md-6' : ''}`}>
            {sectionTitle && <SectionTitle title={sectionTitle} className="mt-4" />}
          </Col>
        </Row>
        <Row className="py-5">
          {image && ((imageAlign === 'left' && !isMobile) || isMobile) && (
            <Col md={5} className={`${hide_mobile_image && 'd-none'} d-md-block mb-4 mb-lg-0`}>
              <Image
                className="mini-banner__image"
                fullImage
                src={get(image, 'entity.images.large.url')}
                alt={get(image, 'entity.images.alt')}
                shadow={false}
                roundedCorners={true}
              />
            </Col>
          )}

          <Col sm={12} md={image ? 6 : 8} className={`${image && imageAlign === 'left' ? 'offset-md-1' : ''}`}>
            <ContentBlock>
              {get(data, 'title') ? (
                <Typography variant="h2" cssClass="mb-3 title-bold-md title-text">
                  {get(data, 'title')}
                </Typography>
              ) : null}

              {description && <Description className="mb-3 mini-banner-content">{description}</Description>}

              {get(button, 'entity.link.url.path') && (
                <div className="bloco-conteudo-footer">
                  <LinkHandler
                    className="footer-button btn btn-primary btn-lg"
                    id={`${get(data, 'id')}_button`}
                    to={get(button, 'entity.link.url.path')}
                  >
                    {get(button, 'entity.link.title')}
                    <i className={`icone-${get(button, 'entity.linkType.entity.token')} ml-2`}></i>
                  </LinkHandler>
                </div>
              )}
            </ContentBlock>
          </Col>

          {image && imageAlign === 'right' && (
            <Col md={6} className="d-none d-md-flex align-items-center">
              <Image
                className="mini-banner__image"
                fullImage
                src={get(image, 'entity.images.large.url')}
                alt={get(image, 'entity.images.alt')}
                shadow={false}
                roundedCorners
                lazyloadClassName="d-md-flex align-items-center w-100"
              />
            </Col>
          )}
        </Row>
      </Container>
    </Container>
  )
}

export default MiniBanner
