import React, { useEffect, useRef, useState } from 'react'
import { XMasonry, XBlock } from 'react-xmasonry'

import './GridGallery.scss'

function GridGallery({ items, className }) {
  const [galleryImages, setGalleryImages] = useState([])
  const [largestWidth, setLargestWidth] = useState(0)
  const mounted = useRef(true)

  function setImages(index, image, { width, height }) {
    if (width > largestWidth) setLargestWidth(width)

    setGalleryImages((galleryImage) => {
      galleryImage.push({ index, width, height, ...image })
      return [...galleryImage]
    })
  }

  function setVideo(image) {
    setGalleryImages((galleryImage) => {
      image.video = image.video.replace('watch?v=', 'embed/')
      galleryImage.push(image)
      return [...galleryImage]
    })
  }

  useEffect(() => {
    if (mounted.current) {
      mounted.current = false
      items.map((item, index) => {
        if (item.video) return setVideo(item)
        const img = new Image()
        img.onload = () => setImages(index, item, img)
        img.src = item.src
      })
    }
  }, [])

  return (
    <div className={`gallery ${className}`}>
      <XMasonry maxColumns={2}>
        {galleryImages
          .sort((prev, next) => prev.index - next.index)
          .map((image, index) => (
            <XBlock
              key={image.src + index}
              width={
                (image.width > largestWidth * 0.67 && image.width > image.height) || galleryImages.length === 1 ? 2 : 1
              }
            >
              <div className={`gallery__block ${image.video && 'video'}`}>
                {image.video ? (
                  <iframe src={image.video} height="560" scrolling="no" frameBorder="0" allowFullScreen />
                ) : (
                  <img src={image.src} className="gallery__image" alt={`Imagem representativa ${image.src}`} />
                )}
              </div>
            </XBlock>
          ))}
      </XMasonry>
    </div>
  )
}

export default GridGallery
